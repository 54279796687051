<template>
  <div  class="app-container">
    <header  class="menu">
      <ul>
        <li><a  href="#a">A</a></li>
        <li><a  href="#b">B</a></li>
        <li><a  href="#c">C</a></li>
      </ul>
    </header>
    <main  class="main-content">
		<router-view></router-view>
    </main>
  </div>
</template>

<script>
/* eslint-disable */
export  default  {
  name:  'App'
}
</script>

<style  scoped>
.app-container  {
  display:  flex;
  flex-direction:  column;
  height:  100vh;
  background-color:  #f4f4f4;
}

.menu  {
  background-color:  #333;
  padding:  20px;
}

.menu  ul  {
  list-style-type:  none;
  padding:  0;
}

.menu  ul  li  {
  display:  inline;
  margin-right:  10px;
}

.menu  ul  li  a  {
  color:  white;
  text-decoration:  none;
}

.main-content  {
  flex-grow:  1;
  padding:  20px;
  overflow-y:  auto;
  background-color: #fff;
}

/*  媒体查询，针对小屏幕的样式  */
@media  (max-width:  600px)  {
  .menu  ul  li  {
    display:  block;
    margin-bottom:  5px;
  }
}
</style>
