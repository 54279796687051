<template>
	<div class="main_Page">
		<div>
			<p class="question">1.js中的数据类型</p>
			<p class="answer">数据类型:number,string,boolean,null,undefined,symbol,bigint,object</p>
		</div>
		<div>
			<p class="question">2.数据类型检测方法</p>
			<p class="answer">(1)typeof、对于null,对象,数组的判断都为object,其他判断都正确</p>
			<p class="answer">(2)instanceof、对于基本数据类型无法做判断,只能对于复杂数据类型做判断</p>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {

		}
	},
}
</script>
<style lang="scss" scoped>

</style>
