import Vue from 'vue'
import VueRouter from 'vue-router'
import AboutView from '../views/AboutView.vue'
import JavascriptView from '../views/javaScript/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: AboutView,
	children: [
		{
			path: '/jsView',
			name: 'JavascriptView',
			component: JavascriptView
		},
	]
  },
]

const router = new VueRouter({
  routes
})

export default router
